import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: 'Marcia Street, Cyrildene, South Africa',
            phone: '+27 62 845 8984',
            time: 'Mon-Sat: 8am - 6pm',
            // socials: [
            //     { platform: 'e-mail', url: '#' },
            //     { platform: 'twitter', url: '#' },
            //     { platform: 'pinterest-p', url: '#' },
            //     { platform: 'rss', url: '#' }
            // ]
        };
    }

    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap">
                        <div className="top-bar-content">
                            <div className="inner">
                                <span className="address content">{this.state.address}</span>
                            </div>
                        </div>
                        <div className="top-bar-socials">
                            <div className="inner">
                                {/*<span className="phone content">{this.state.phone}</span>*/}
                                <span className="time content">{this.state.time}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;
