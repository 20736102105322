import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IconBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconbox : [
                {
                    id: 1,
                    icon: "fa fa-cogs", // Font Awesome icon for "What We Offer"
                    title: "What We Offer",
                    text: "End-to-end procurement, supplier vetting, logistics management, and more."
                },
                {
                    id: 2,
                    icon: "fa fa-industry", // Font Awesome icon for "Industries Served"
                    title: "Industries Served",
                    text: "Mining, Engineering, Agriculture, Construction, Retail, and Government."
                },
                {
                    id: 3,
                    icon: "fa fa-thumbs-up", // Font Awesome icon for "Why Procure.Pro"
                    title: "Why Procure.Pro",
                    text: "Concise and efficient techniques, transparent processes, and customized solutions."
                }
            ]
        }
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.iconbox.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 5px 0 5px" data-mobilemargin="0 0 0 0">
                                <div className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
                                    <div className="icon-wrap">
                                        <i className={data.icon} />
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading"><Link to="#">{data.title}</Link></h5>
                                        <div className="sep clearfix" />
                                        <p className="sub-heading">{data.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default IconBox;
