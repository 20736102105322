import Home from './Home'
import Home02 from './Home02'
import AboutUs from './AboutUs'
import AboutDetails from './AboutDetails'
import AboutTeam from './AboutTeam'
import AboutCarres from './AboutCarres'
import Services from './Services'
import ServiceDetail from './ServiceDetail'
import Projects from './Projects'
import ProjectFullWidth from './ProjectFullWidth'
import ProjectDetail from './ProjectDetail'
import Testimonial from './Testimonial'
import SubscriptionPlans from './SubscriptionPlans'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import ContactUs from './ContactUs'
import Contact02 from './Contact02'

const routes = [
    { path: '/', component: Home},
    // { path: '/home-02', component: Home02},
    { path: '/about-us', component: AboutUs},
    // { path: '/about-detail', component: AboutDetails},
    // { path: '/about-team', component: AboutTeam},
    // { path: '/about-carees', component: AboutCarres},
    { path: '/services', component: Services},
    // { path: '/services-detail', component: ServiceDetail},
    // { path: '/project', component: Projects},
    // { path: '/project-full-width', component: ProjectFullWidth},
    // { path: '/project-detail', component: ProjectDetail},
    // { path: '/page-testimonial', component: Testimonial},
    { path: '/subscription-plans', component: SubscriptionPlans},
    // { path: '/blog', component: Blog},
    // { path: '/blog-single', component: BlogSingle},
    { path: '/contact-us', component: ContactUs},
    // { path: '/contact-02', component: Contact02},
]

export default routes;
