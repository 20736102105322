import React, { Component } from 'react';
import Slider from "../testimonial/Slider";
import {Link} from "react-router-dom";

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial : [
                {
                    id: 1,
                    text: '“ I have worked with Procure.Pro Construction Services for over 10 years, they have consistently delivered fair pricing, quality workmanship and complete projects on schedule. I appreciate the level of customer service they deliver by going above and beyond expectations. ”',
                    name: 'PAUL PIGNATARO'
                },
                {
                    id: 2,
                    text: '“ I have worked with Procure.Pro Construction Services for over 10 years, they have consistently delivered fair pricing, quality workmanship and complete projects on schedule. I appreciate the level of customer service they deliver by going above and beyond expectations. ”',
                    name: 'PAUL PIGNATARO'
                },
                {
                    id: 3,
                    text: '“ I have worked with Procure.Pro Construction Services for over 10 years, they have consistently delivered fair pricing, quality workmanship and complete projects on schedule. I appreciate the level of customer service they deliver by going above and beyond expectations. ”',
                    name: 'PAUL PIGNATARO'
                }
            ],
            slidetop: [
                {
                    id: 1,
                    srcimg: 'assets/img/testimonials/customer-1-100x100.png',
                    text: '“ Procure.Pro has transformed our supply chain management. Their prompt delivery and reliable suppliers have significantly improved our operations. ”',
                    names: 'NKOSINATHI MABUZA',
                    position: 'Operations Manager'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/testimonials/customer-2-100x100.png',
                    text: '“ The personalized solutions offered by Procure.Pro have been a game-changer for our engineering projects. Their efficiency and transparency are unmatched. ”',
                    names: 'THANDIWE NKOSI',
                    position: 'Project Lead'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/testimonials/customer-3-100x100.png',
                    text: '“ We have seen remarkable growth in our agricultural business thanks to Procure.Pro’s seamless procurement process and top-tier suppliers. ”',
                    names: 'KOFI MENSAH',
                    position: 'CEO'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/testimonials/customer-4-100x100.png',
                    text: '“ Procure.Pro’s logistics management has ensured timely and secure delivery of our retail products, enhancing our customer satisfaction. ”',
                    names: 'AMARA OKAFOR',
                    position: 'Logistics Manager'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/testimonials/customer-5-100x100.png',
                    text: '“ The subscription options provided by Procure.Pro have given us the flexibility to scale our procurement needs as our construction projects grow. ”',
                    names: 'JABARI KAMARA',
                    position: 'Construction Manager'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/testimonials/customer-6-100x100.png',
                    text: '“ Procure.Pro’s end-to-end procurement services have streamlined our government projects, ensuring efficiency and reliability. ”',
                    names: 'ZURI DIALLO',
                    position: 'Procurement Officer'
                }
            ],
            slidebottom: [
                {
                    id: 1,
                    srcimg: 'assets/img/testimonials/customer-4-100x100.png',
                    text: '“ Procure.Pro’s supplier vetting process has connected us with the best suppliers, ensuring high-quality products for our engineering needs. ”',
                    names: 'AYANDA KHUMALO',
                    position: 'Head of Procurement'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/testimonials/customer-5-100x100.png',
                    text: '“ The efficiency and reliability of Procure.Pro’s services have been instrumental in the success of our agricultural operations. ”',
                    names: 'CHINEDU EZE',
                    position: 'Farm Manager'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/testimonials/customer-6-100x100.png',
                    text: '“ Procure.Pro’s transparent procurement process has given us peace of mind, knowing we are getting the best deals and quality. ”',
                    names: 'FATIMA BAKO',
                    position: 'Procurement Specialist'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/testimonials/customer-1-100x100.png',
                    text: '“ The prompt delivery and personalized solutions from Procure.Pro have greatly benefited our construction projects. ”',
                    names: 'MALIK JUMA',
                    position: 'Project Manager'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/testimonials/customer-2-100x100.png',
                    text: '“ Procure.Pro’s logistics management has ensured our government projects are completed on time and within budget. ”',
                    names: 'NIA KABORE',
                    position: 'Logistics Coordinator'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/testimonials/customer-3-100x100.png',
                    text: '“ The flexibility of Procure.Pro’s subscription services has allowed us to adapt our procurement needs as our business grows. ”',
                    names: 'KWEKU BOATENG',
                    position: 'Business Development Manager'
                }
            ]
        }
    }

    render() {
        return (
            <div id="main-content" className="row-testimonials clearfix">
                <div id="content-wrap">
                    <div id="site-content" className="site-content clearfix">
                        <div id="inner-content" className="inner-content-wrap">
                            <div className="page-content">
                                <div className="row-testimonials">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop={42} data-mobile={35}
                                                     data-smobile={35}/>
                                                <div
                                                    className="themesflat-headings style-1 text-center clearfix">
                                                    <h2 className={'heading'}>{'Client Success Stories'}</h2>
                                                    <div className="sep has-icon width-125 clearfix">
                                                        <div className="sep-icon">
                                                            <span className="sep-icon-before sep-center sep-solid"/>
                                                            <span className="icon-wrap"><i
                                                                className="fa fa-user"/></span>
                                                            <span className="sep-icon-after sep-center sep-solid"/>
                                                        </div>
                                                    </div>
                                                    <div className="themesflat-spacer clearfix"
                                                         data-desktop={42} data-mobile={35}
                                                         data-smobile={35}/>
                                                </div>
                                                <div className="themesflat-carousel-box clearfix"
                                                     data-gap={30} data-column={3} data-column2={2}
                                                     data-column3={1}
                                                     data-auto="true">
                                                    <div className="owl-carousel owl-theme">
                                                        {
                                                            this.state.slidetop.map(data => (
                                                                <div
                                                                    className="themesflat-testimonials style-3 align-center has-width w35 circle clearfix"
                                                                    key={data.id}>
                                                                    <div className="testimonial-item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img src={data.srcimg}
                                                                                     alt="altimage"/>
                                                                                <span
                                                                                    className="icon-wrap">
                                                        <i className="fa fa-quote-left"/>
                                                    </span>
                                                                            </div>
                                                                            <blockquote
                                                                                className="text">
                                                                                <p>{data.text}</p>
                                                                                <div
                                                                                    className="name-pos clearfix">
                                                                                    <h6 className="name">{data.names}</h6>
                                                                                    <span
                                                                                        className="position">{data.position}</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop={30} data-mobile={35}
                                                     data-smobile={35}/>
                                                <div className="themesflat-carousel-box clearfix"
                                                     data-gap={30} data-column={3} data-column2={2}
                                                     data-column3={1}
                                                     data-auto="true">
                                                    <div className="owl-carousel owl-theme">
                                                        {
                                                            this.state.slidebottom.map(data => (
                                                                <div
                                                                    className="themesflat-testimonials style-3 align-center has-width w35 circle clearfix"
                                                                    key={data.id}>
                                                                    <div className="testimonial-item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img src={data.srcimg}
                                                                                     alt="altimage"/>
                                                                                <span
                                                                                    className="icon-wrap">
                                                        <i className="fa fa-quote-left"/>
                                                    </span>
                                                                            </div>
                                                                            <blockquote
                                                                                className="text">
                                                                                <p>{data.text}</p>
                                                                                <div
                                                                                    className="name-pos clearfix">
                                                                                    <h6 className="name">{data.names}</h6>
                                                                                    <span
                                                                                        className="position">{data.position}</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop={42} data-mobile={35}
                                                     data-smobile={35}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial;
