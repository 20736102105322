const menus = [
    {
        id: 1,
        name: 'Home',
        link: '/',
        namesub: []
    },
    {
        id: 2,
        name: 'About Us',
        link: '/about-us',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'About Us',
            //     links: '/about-us'
            // },
            // {
            //     id: 2,
            //     sub: 'About Detail',
            //     links: '/about-detail'
            // },
            // {
            //     id: 3,
            //     sub: 'About Team',
            //     links: '/about-team'
            // },
            // {
            //     id: 4,
            //     sub: 'About Carees',
            //     links: '/about-carees'
            // }
        ],
    },{
        id: 3,
        name: 'Services',
        link: '/services',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Services',
            //     links: '/services'
            // },
            // {
            //     id: 2,
            //     sub: 'Services Detail',
            //     links: '/services-detail'
            // }
        ],
    },
    {
        id: 4,
        name: 'Subscription Plans',
        link: '/subscription-plans',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Subscription Plans',
            //     links: '/page-pricing'
            // },
            // {
            //     id: 2,
            //     sub: 'Projects Full Width',
            //     links: '/project-full-width'
            // },
            // {
            //     id: 3,
            //     sub: 'Projects Detail',
            //     links: '/project-detail'
            // }
        ],
    },
    {
        id: 5,
        name: 'Contact Us',
        link: '/contact-us',
        namesub: [],
    }
]

export default menus;
