import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Product Sourcing & Supplier Vetting: We connect businesses with vetted, reliable suppliers in South Africa and beyond.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Logistics Management: In partnership with leading logistics companies, we ensure timely and secure delivery.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'End-to-End Procurement: Complete management of procurement, from quoting to delivery.'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Supply Chain Optimization: Our efficient processes result in operations running at optimum levels.'
                },
                {
                    id: 5,
                    icon: 'fa fa-check-square',
                    text: 'Subscription Options: Different tiers of subscription services are available, providing flexibility based on the level of engagement required.'
                },
                {
                    id: 6,
                    icon: 'fa fa-check-square',
                    text: 'Per-Enquiry Services: For clients seeking more ad-hoc support, we also offer procurement services on a per-enquiry basis.'
                },
            ]
        }
    }

    render() {
        return (
            <div className="row-about">
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-6 half-background style-1">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60}
                                 data-smobile={60}/>
                            <img src="/assets/img/img1.webp" alt="Procure.Pro" style={{objectFit: "contain"}}/>
                        </div>
                        <div className="col-md-6 bg-light-grey">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">Welcome to Procure.Pro</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                                    {/*<p className="sub-heading margin-top-30">*/}
                                    {/*    Procure.Pro connects African businesses with world-class suppliers, focusing on South Africa. Our efficient techniques ensure optimal operations. We offer a transparent procurement process for mining, engineering, agriculture, construction, retail, and government sectors. Our services include product sourcing, supply chain management, and prompt delivery with personalized solutions.*/}
                                    {/*</p>*/}
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35}
                                     data-smobile={35}/>
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                        <div className="inner">
                                            {
                                                this.state.inner.map(data => (
                                                    <span className="item" key={data.id}>
                                                        <span className="icon"><i className={data.icon} /></span>
                                                        <span className="text">{data.text}</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                <div className="elm-button">
                                    <Link to="/contact-us" className="themesflat-button bg-white">{'Get in Touch'}</Link>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
