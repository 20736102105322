import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {TitleHeading, IconBox, About, Service, Project, ServicesBox, Testimonial, Overview, Faq, Partner} from '../layouts/home/index'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Home",
                }
            ],
            headingServiceExpertise: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'Our Services and Expertise',
                    text: 'Comprehensive Solutions Tailored to Your Needs',
                    classtext: 'sub-heading',
                }
            ],
            headingservice: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'BEST SERVICES',
                    text: 'End-to-end procurement solutions tailored for Mining, Engineering, Agriculture, Construction, Retail, and Government.',
                    classtext: 'sub-heading',
                }
            ],
            headingproject: [
                {
                    id: 1,
                    classnames: 'heading text-white',
                    title: 'FEATURED IMPACT',
                    classtext: 'sub-heading',
                    text: 'Empowering businesses in the DRC by connecting them to top-tier suppliers in South Africa.',
                }
            ],
            servicebox: [
                {
                    id: 1,
                    classnames: 'heading font-size-30',
                    title: 'EXPLORE OUR SERVICES',
                    classtext: 'sub-heading font-weight-400',
                    text: 'From supplier vetting to logistics management, discover how Procure.Pro can optimize your operations.',
                }
            ]

        }
    }

    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            {/*<div className="rev_slider_wrapper fullwidthbanner-container">*/}
                                            {/*    <div id="rev-slider1" className="rev_slider fullwidthabanner">*/}
                                            {/*        <Slider/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="row-call-to-action" style={{backgroundColor: '#0062e9'}}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix"
                                                                 data-desktop={42} data-mobile={35} data-smobile={35}/>
                                                            <div
                                                                className="themesflat-headings style-1 text-center clearfix">
                                                                <h2 className={'heading text-white'}>{'Your Professional Procurement Partner in Africa'}</h2>
                                                                <div className="sep has-icon width-125 clearfix">
                                                                    <div className="sep-icon">
                                                                        <span
                                                                            className="sep-icon-before sep-center sep-solid"
                                                                            style={{background: "white"}}/>
                                                                        <span className="icon-wrap"><i
                                                                            className="fa fa-handshake-o" style={{color: "white"}}/></span>
                                                                        <span
                                                                            className="sep-icon-after sep-center sep-solid"
                                                                            style={{background: "white"}}/>
                                                                    </div>
                                                                </div>
                                                                <p className={'sub-heading text-white'}>{'Connecting African businesses to world-class suppliers—promptly and efficiently.'}</p>
                                                                <div className="themesflat-spacer clearfix"
                                                                     data-desktop={42} data-mobile={35}
                                                                     data-smobile={35}/>
                                                                <div className="elm-button text-center">
                                                                    <Link to="/subscription-plans"
                                                                          className="themesflat-button bg-inverse big ml-2 mr-2"><span>{'Explore Subscriptions'}</span></Link>
                                                                    <Link to="/contact-us"
                                                                          className="themesflat-button bg-white big ml-2 mr-2"><span>{'Request a Quote'}</span></Link>
                                                                </div>
                                                            </div>
                                                            <div className="themesflat-spacer clearfix"
                                                                 data-desktop={42} data-mobile={35} data-smobile={35}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix"
                                                                 data-desktop={42} data-mobile={35} data-smobile={35}/>
                                                            {
                                                                this.state.headingServiceExpertise.map(data => (
                                                                    <TitleHeading data={data} key={data.id}/>
                                                                ))
                                                            }
                                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                                        </div>
                                                    </div>

                                                    <IconBox />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                                            <div className="elm-button text-center">
                                                                <Link to="/about-us" className="themesflat-button bg-accent">{'About Us'}</Link>
                                                            </div>
                                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <About />
                                            <Testimonial />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
